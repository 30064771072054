import { Grid, Typography, Button } from "@mui/material";

export default function Grow() {

    function handleButtonClick() {
		// Go to cal.com

        const url = "https://cal.com/popclip/poptext-intro-meeting"

        window.location.replace(url)
	}


	return (
		<Grid
			container
			justifyContent={"center"}
			sx={{ marginTop: "3em", marginBottom:"5em" }}
			spacing={3}
		>
			<Grid item xs={12}>
				<Typography
					sx={{
						// Dominate the feed
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
						textAlign: "center",
					}}
				>
					Grow Your Newsletter
				</Typography>
			</Grid>

			<Grid item sx={{ marginTop: "2em", marginBottom: "2em" }} xs={12} sm={8}>
				<Typography
					sx={{
						//  Post your polished, eye-catching videos and watch your audience grow, your brand shine, and your message spread like wildfire.
						color: "#E0E6F0",
						fontSize: "24px",
						fontFamily: "Roboto",
						fontWeight: "700",
						wordWrap: "break-word",
						textAlign: "center",
					}}
				>
					Post your polished, eye-catching videos and watch your audience grow,
					your brand shine, and your message spread like wildfire.
				</Typography>
			</Grid>

			<Grid item spacing={2} xs={12} justifyContent={"center"} style={{textAlign:"center"}}>
				<Button sx={{width:"250px", height:"50px", color:"#FB5607", fontFamily:"Roboto", fontWeight:"700", fontSize:"24px", background:"linear-gradient(114deg, #FFBE0B 5.54%, #F7CD59 105.02%)", borderRadius:"10px" }} onClick={handleButtonClick}>Book a call</Button>
                
			</Grid>
		</Grid>
	);
}
