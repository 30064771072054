
import Hero from "./Hero"
import HowItWorks from "./HowItWorks";
import Feed from "./Feed";
import WhatWeDo from "./WhatWeDo";
import WeSpecialize from "./WeSpecialize";
import Price from "./Price";
import Grow from "./Grow";


import Menubar from "../../Components/Menubar"
import {Container, Grid } from "@mui/material"


import { ThemeProvider, createTheme } from "@mui/material/styles";


export default function Landing(){

    const themeLight = createTheme({
        palette: {
          background: {
            default: "#e4f0e2"
          }
        }
    });

    const themeDark = createTheme({
        palette: {
          background: {
            default: "#051625"
          },
          text: {
            primary: "#ffffff"
          }
        }
      });



    const themeX = createTheme({
        palette: {
          mode: "dark",
        }
      });

    return(
        <ThemeProvider theme={themeDark}>
        <Container sx={{backgroundColor : "#051625"}}>
            <Grid container>
            <Grid container item>
                <Menubar/>
            </Grid>
            <Grid container item id="Hero" justifyContent={"center"}>
                <Hero/>
            </Grid>
            <Grid container item><HowItWorks/></Grid>
            {/* <Grid container item><Feed/></Grid> */}
            <Grid container item><WhatWeDo/></Grid>
            <Grid container item><WeSpecialize/></Grid>
            <Grid container item><Price/></Grid>
            <Grid container item><Grow/></Grid>

            </Grid>
        </Container>
        </ThemeProvider>
    )
}