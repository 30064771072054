import { Grid, Typography, Button } from "@mui/material";

export default function Price() {
	const boxText = [
		{
			title: "Starter",
			price: "$199",
			text: ["per video", "$796 total", "4 Video credits", 
			// "Simple animations and transitions"
		],
		},
		{
			title: "Growth",
			price: "$149",
			text: ["per video", "$1490 total","10 Video credits", 
			// "Advanced animations and custom graphics"
		],
		},
		{
			title: "Pro",
			price: "$99",
			text: [
				"per video", "$2970 total",
				"30 Video credits",
				// "Premium custom animations, including intros and outros",
			],
		},
	];

	function handleButtonClick(event: React.MouseEvent<HTMLElement>) {
		const id = event.currentTarget.id;

        let url = "https://cal.com/popclip/poptext-intro-meeting"  //Starter

		if (id === "Growth") url = "https://cal.com/popclip/poptext-intro-meeting"

		if (id === "Pro") url = "https://cal.com/popclip/poptext-intro-meeting"
			
        window.location.replace(url)
	}

	function BoxView({
		title,
		price,
		text,
	}: {
		title: string;
		price: string;
		text: string[];
	}) {
		return (
			// <Grid container item xs={12} sm={4} justifyContent="center">

			<Grid
				container
				item
				xs={12}
				sm={3.5}
				justifyContent="center"
				sx={{
					backgroundColor: "#fff",
					padding: "1em",
					borderRadius: "10px",
					height: "400px",
                    // marginLeft : {(title === "Growth") ? "20px" : "0px"}
				}}
				style={{ textAlign: "center", marginLeft : title === "Growth" ? "20px" : "0px", marginRight : title === "Growth" ? "20px" : "0px"  }}
			>
				{/* Price Name */}
				<Grid item xs={12} sx={{ marginBottom: "1em" }}>
					<Typography
						sx={{
							// Subscribe and Simplify<br/>
							color: "#FFBE0B",
							fontSize: "50px",
							fontFamily: "Roboto",
							fontWeight: "900",
							wordWrap: "break-word",
							textAlign: "center",
						}}
					>
						{title}
					</Typography>
				</Grid>

				{/* Price Amount */}
				<Grid item xs={12} sx={{ marginBottom: "1em" }}>
					<Typography
						sx={{
							// Subscribe and Simplify<br/>
							color: "#051625",
							fontSize: "40px",
							fontFamily: "Roboto",
							fontWeight: "900",
							wordWrap: "break-word",
							textAlign: "center",
						}}
					>
						{price}
					</Typography>
				</Grid>

				{text.map((t) => {
					return (
						<Grid item style={{ textAlign: "center" }} xs={12}>
							<Typography
								sx={{
									color: "#888888",
									fontSize: "20px",
									fontFamily: "Roboto",
									fontWeight: "700",
									// lineHeight: 28.19,
									wordWrap: "break-word",
								}}
							>
								{t}
							</Typography>
						</Grid>
					);
				})}

				<Button
					style={{
						color: "#FB5607",
						textAlign: "center",
						fontFamily: "Roboto",
						fontSize: "26px",
						fontStyle: "normal",
						fontWeight: "900",
					}}
					id={title}
					onClick={handleButtonClick}
				>
					Start Now
				</Button>
			</Grid>
		);
	}

	return (
		<Grid
			container
			item
			sx={{
				borderRadius: "20px",
				background: "linear-gradient(121deg, #3A86FF 8.18%, #75AAFF 103.44%)",
				// height: "800px",
				marginTop: "8em",
				padding: "3em 2em 4em 2em",
			}}
			justifyContent={"center"}
		>
			<Grid item sx={{ marginBottom: "1em" }}>
				<Typography
					sx={{
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
					}}
				>
					Price
				</Typography>
			</Grid>
			<Grid item xs={12} sx={{ marginBottom: "1em", textAlign:"center" }}>
				<Typography
					sx={{
						color: "white",
						fontSize: 20,
						fontFamily: "Roboto",
						fontWeight: "700",
						wordWrap: "break-word",
					}}
				>
					No Subscription. Single Purchase Credits
				</Typography>
			</Grid>

			<Grid container item id="PriceBox" justifyContent={"center"}>
				{boxText.map((v) => {
					return <BoxView title={v.title} price={v.price} text={v.text} />;
				})}
			</Grid>
		</Grid>
	);
}
