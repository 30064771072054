import { Grid, Typography, Button } from "@mui/material";
import { motion } from "framer-motion"

export default function Hero() {
	function handleButtonClick() {
		// Go to cal.com

        const url = "https://cal.com/popclip/poptext-intro-meeting"

        window.location.replace(url)
	}

	return (
		
		<Grid
			container
			item
			xs={10}
			justifyContent={"center"}
			style={{ textAlign: "center" }}
			spacing={6}
            marginTop={"3em"}
		>
			<motion.div
  initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
>

	<Grid container item justifyContent={"center"} spacing={6}>
			<Grid item>
				<Typography
					sx={{
						color: "#fff",
						fontFamily: "Roboto",
						fontSize: "80px",
						fontStyle: "normal",
						fontWeight: 900,
						lineHeight: "normal",
					}}
				>
					{/* Unleash the Power of  */}
					Grow your newsletter with
				</Typography>
				<Typography
					sx={{
						color: "#8338EC",
						fontFamily: "Roboto",
						fontSize: "80px",
						fontStyle: "normal",
						fontWeight: 900,
						lineHeight: "normal",
					}}
				>
					Viral Video Content
				</Typography>

				
			</Grid>

            <Grid item xs={10} style={{textAlign:"center"}}>
					<Typography
						sx={{
							color: "#E0E6F0",
							fontFamily: "Roboto",
							fontSize: "24px",
							fontStyle: "normal",
							fontWeight: 700,
							lineHeight: "normal",
                            
						}}
					>
						Convert your audience on TikTok, Instagram, and YouTube with
						professional videos created from your newsletter
						 {/* editing and eye-popping animations. */}
					</Typography>
				</Grid>

			<Grid item>
				<Button
					onClick={handleButtonClick}
					sx={{
						width: "250px",
						height: "50px",
						borderRadius: "10px",
						backgroundColor: "#E0E6F0",
						color: "#051625",
						'&:hover': {
							backgroundColor: '#8338EC',
							color: '#E0E6F0',
						},
						
					}}
				>
					Contact Us
				</Button>
			</Grid>
			</Grid>
			</motion.div>
		</Grid>
		
	);
}
