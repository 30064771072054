import { Grid, Typography } from "@mui/material";



export default function WhatWeDo(){

    const boxText = [
        {
            title : "Custom Video Editing",
            text : "Our editing team transforms your footage into sleek, professional clips that audiences love to watch and share. "
        },
        {
            title : "Dynamic Animations",
            text : "From subtle accents to full-blown animated stories, we infuse your message with visual flair that captures attention and doesn't let go."
        },
        {
            title : "Social Media Optimized",
            text : "Each video is cut, captioned, and crafted to perform optimally on TikTok, Instagram, and YouTube, ensuring maximum reach and engagement."
        }
    ]


    function BoxView({title, text}:{title:string, text:string}){

        return(
            <Grid container item xs={12} sm={4} justifyContent="center">
					<Grid item sx={{marginBottom:"1em"}}>
						<Typography
							sx={{
								// Subscribe and Simplify<br/>
								color: "#31125D",
								fontSize: 28,
								fontFamily: "Roboto",
								fontWeight: "900",
								wordWrap: "break-word",
							}}
						>
							{title}
						</Typography>
					</Grid>

					<Grid item sx={{ backgroundColor: "#fff", padding:"2em", borderRadius:"10px", height:"260px" }} style={{textAlign:"center"}}>
						<Typography
							sx={{
								color: "#888888",
								fontSize: "20px",
								fontFamily: "Roboto",
								fontWeight: "700",
								// lineHeight: 28.19,
								wordWrap: "break-word",
							}}
						>
							{text}
						</Typography>
					</Grid>
				</Grid>
        )

    }


	return (
		<Grid
			container
			sx={{
				borderRadius: "20px",
				background: "linear-gradient(112deg, #8338EC 9.85%, #A471EC 83.03%)",
              
				marginTop: "8em", padding:"3em 1em 4em 1em"
			}}
			justifyContent={"center"}
		>
			<Grid item sx={{marginBottom:"1em"}}>
				<Typography
					sx={{
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
					}}
				>
					What We Do
				</Typography>
			</Grid>

			<Grid container spacing={2} justifyContent={"center"}>
				
                {
                    boxText.map(v=>{
                        return <BoxView title={v.title} text={v.text} />
                    })
                }


			</Grid>
		</Grid>
	);

}