import { Grid, Typography } from "@mui/material";
import {motion} from "framer-motion"

export default function HowItWorks() {


    const boxText = [
        {
            title : "Come on board",
            text : 
			"Kickstart your journey with a white glove service where we dive deep in to your newsletters"
			// "Kickstart your journey with a white glove service where we deep dive in to your newsletters to immerse ourselves in your brand and audience"
        },
        {
            title : "Send Us Your Raw Genius",
            text : "Send us your upcoming newsletter and any additional raw media you’re looking to transform"
        },
        {
            title : "Watch your audience grow",
            text : "We convert your newsletters into highly engaging shorts that pop on social networks"
        }
    ]

	const boxText2 = [
        {
            title : "UGC Ads",
            text : "Great performing User Generated Content Ads with high conversion rates at a fraction of the cost"
        },
        {
            title : "Long form to Shorts",
            text : "Increase engagement with attention grabbing shorts of your Youtube, Podcast, Testimonials and Interview videos"
        },
        {
            title : "Text to Video",
            text : "We convert your newsletters into highly engaging shorts that pop"
        }
    ]


    function BoxView({title, text}:{title:string, text:string}){

        return(
            <Grid container item xs={12} sm={4} justifyContent="center">
					<Grid item sx={{marginBottom:"1em"}}>
						<Typography
							sx={{
								// Subscribe and Simplify<br/>
								color: "#FB5607",
								fontSize: 28,
								fontFamily: "Roboto",
								fontWeight: "900",
								wordWrap: "break-word",
							}}
						>
							{title}
						</Typography>
					</Grid>

					<motion.div
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						viewport={{ once: true }}
						>

					<Grid item sx={{ backgroundColor: "#fff", padding:"2em", borderRadius:"10px", height:"200px" }} style={{textAlign:"center"}}>
						<Typography
							sx={{
								color: "#888888",
								fontSize: "20px",
								fontFamily: "Roboto",
								fontWeight: "700",
								// lineHeight: 28.19,
								wordWrap: "break-word",
							}}
						>
							{text}
						</Typography>
					</Grid>
					</motion.div>
				</Grid>
        )

    }


	return (
		<Grid
			container
			sx={{
				borderRadius: "20px",
				background: "linear-gradient(114deg, #FFBE0B 5.54%, #FFD86B 105.02%)",
				marginTop: "8em", padding:"3em 1em 4em 1em"
			}}
			justifyContent={"center"}
		>
			<Grid item sx={{marginBottom:"1em"}}>
				<Typography
					sx={{
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
					}}
				>
					How it works
					{/* Our Services */}
				</Typography>
			</Grid>

			<Grid container spacing={2} justifyContent={"center"}>
				
                {
                    boxText.map(v=>{
                        return <BoxView title={v.title} text={v.text} />
                    })
                }


			</Grid>
		</Grid>
	);
}
