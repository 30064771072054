import { Grid, Typography } from "@mui/material";

import tiktok from "../../assets/tiktok.png";
import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";

import { motion } from "framer-motion";

export default function WeSpecialize() {
	const boxText = [
		{
			title: "Tiktok Videos",
			img: <img src={tiktok} />,
		},
		{
			title: "Instagram Reels",
			img: <img src={instagram} />,
		},
		{
			title: "Youtube Shorts",
			img: <img src={youtube} />,
		},
	];

	function BoxView({
		title,
		imgElement,
	}: {
		title: string;
		imgElement: JSX.Element;
	}) {


		let duration = 2

		if(title === "Instagram Reels") duration = 1.5
		if(title === "Youtube Shorts") duration = 1.7


		return (
			<Grid
				container
				item
				xs={12}
				sm={4}
				justifyContent="center"
				sx={{ marginTop: "8em" }}
			>
				<Grid item sx={{ marginBottom: "1em" }} xs={12}>
					<Typography
						sx={{
							// Subscribe and Simplify<br/>
							color: "#3A86FF",
							fontSize: 28,
							fontFamily: "Roboto",
							fontWeight: "900",
							// wordWrap: "break-word",
							textAlign: "center",
						}}
					>
						{title}
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{ padding: "2em", borderRadius: "10px" }}
					style={{ textAlign: "center" }}
				>
					<motion.div
						animate={{ y :10 }}
						transition={{ repeat: Infinity, duration: duration, repeatType: "reverse" }}
						
>
					{imgElement}
					</motion.div>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			sx={{ marginTop: "5em" }}
			spacing={3}
		>
			<Grid item xs={12}>
				<Typography
					sx={{
						// Dominate the feed
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
						textAlign: "center",
					}}
				>
					We specialize in
				</Typography>
			</Grid>

			{/* <Grid item sx={{ marginTop: "2em", marginBottom: "2em" }} xs={12} sm={10}>
				<Typography
					sx={{
						//  Post your polished, eye-catching videos and watch your audience grow, your brand shine, and your message spread like wildfire.
						color: "#E0E6F0",
						fontSize: "24px",
						fontFamily: "Roboto",
						fontWeight: "700",
						wordWrap: "break-word",
						textAlign: "center",
					}}
				>
					Post your polished, eye-catching videos and watch your audience grow,
					your brand shine, and your message spread like wildfire.
				</Typography>
			</Grid> */}

			<Grid container spacing={2} justifyContent={"center"}>
				{boxText.map((v) => {
					return <BoxView title={v.title} imgElement={v.img} />;
				})}
			</Grid>
		</Grid>
	);
}
